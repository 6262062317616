import { faArrowRightFromBracket } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Avatar,
    Burger,
    Divider,
    Drawer,
    Group,
    Menu,
    Stack,
    Text,
    UnstyledButton,
    useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useQueryClient } from "@tanstack/react-query";
import { trackEvent } from "analytics";
import { OZNR_FAQ_LINK, OZNR_SUPPORT_LINK } from "constants/links";
import { BELOW_HEADER_Z_INDEX } from "constants/numbers";
import { UserContext } from "context/userContext";
import { getMerchantLocationPath } from "helpers/url/urlHelpers";
import { formatUserInitials } from "helpers/user/userHelpers";
import Link from "next/link";
import { useRouter } from "next/router";
import { useContext, useState } from "react";
import { AnalyticsEvent } from "types/Analytics";

interface AccountNavProps {
    merchantIdentifier: string;
}

export function AccountNav({ merchantIdentifier }: AccountNavProps) {
    const theme = useMantineTheme();
    const [openedDrawer, setDrawerOpened] = useState(false);
    const [openedMenu, setMenuOpened] = useState(false);
    const router = useRouter();
    const userContext = useContext(UserContext);
    const smallScreen = useMediaQuery(
        `(max-width: ${theme.breakpoints.lg})`,
        false
    );
    const queryClient = useQueryClient();

    function handleLogout() {
        userContext?.logout({ from: router.asPath });
        queryClient.clear();
    }

    // Drawer must not be in conditional for animation to work
    return (
        <>
            <Drawer
                position="top"
                size={575}
                opened={openedDrawer}
                zIndex={BELOW_HEADER_Z_INDEX}
                onClose={() => setDrawerOpened(false)}
                withCloseButton={false}
                trapFocus={false}
                lockScroll={true}
            >
                <Stack mx="sm" mt={40} pt={42} spacing="md">
                    <UnstyledButton
                        component={Link}
                        href={`${getMerchantLocationPath(
                            router.query.merchant as string,
                            merchantIdentifier
                        )}`}
                    >
                        <Text>Shop</Text>
                    </UnstyledButton>
                    <UnstyledButton
                        component={Link}
                        href={`${getMerchantLocationPath(
                            router.query.merchant as string,
                            merchantIdentifier
                        )}/events`}
                    >
                        <Text>Events</Text>
                    </UnstyledButton>
                    <UnstyledButton
                        component={Link}
                        href={`${getMerchantLocationPath(
                            router.query.merchant as string,
                            merchantIdentifier
                        )}/memberships`}
                    >
                        <Text>Memberships</Text>
                    </UnstyledButton>
                    <UnstyledButton
                        component={Link}
                        href={`${getMerchantLocationPath(
                            router.query.merchant as string,
                            merchantIdentifier
                        )}/account`}
                    >
                        <Text>Account</Text>
                    </UnstyledButton>
                    <UnstyledButton
                        component={Link}
                        href={`${getMerchantLocationPath(
                            router.query.merchant as string,
                            merchantIdentifier
                        )}/orders/active`}
                    >
                        <Text>Active Orders</Text>
                    </UnstyledButton>
                    <UnstyledButton
                        component={Link}
                        href={`${getMerchantLocationPath(
                            router.query.merchant as string,
                            merchantIdentifier
                        )}/orders/invites`}
                    >
                        <Text>Proxies</Text>
                    </UnstyledButton>
                    <UnstyledButton
                        component={Link}
                        href={`${getMerchantLocationPath(
                            router.query.merchant as string,
                            merchantIdentifier
                        )}/orders/history`}
                    >
                        <Text>Order History</Text>
                    </UnstyledButton>
                    <UnstyledButton
                        component={Link}
                        href={`${getMerchantLocationPath(
                            router.query.merchant as string,
                            merchantIdentifier
                        )}/orders/memberships`}
                    >
                        <Text>Active Memberships</Text>
                    </UnstyledButton>
                    <UnstyledButton
                        component={Link}
                        href={OZNR_SUPPORT_LINK}
                        target="_blank"
                        onClick={() => {
                            trackEvent(AnalyticsEvent.ViewHelp);
                        }}
                    >
                        <Text>Help Center</Text>
                    </UnstyledButton>
                    <UnstyledButton
                        component={Link}
                        href={OZNR_FAQ_LINK}
                        target="_blank"
                        onClick={() => {
                            trackEvent(AnalyticsEvent.ViewFAQs);
                        }}
                    >
                        <Text>FAQs</Text>
                    </UnstyledButton>
                    <Divider style={{ margin: "0" }} />
                    <UnstyledButton onClick={handleLogout}>
                        <Group position="apart">
                            <Text>Log Out</Text>
                            <FontAwesomeIcon icon={faArrowRightFromBracket} />
                        </Group>
                    </UnstyledButton>
                </Stack>
            </Drawer>
            {smallScreen ? (
                <Burger
                    color={
                        theme.other.isLight
                            ? "black"
                            : theme.colors.merchantPrimary[1]
                    }
                    opened={openedDrawer}
                    onClick={() => setDrawerOpened(!openedDrawer)}
                />
            ) : (
                <Menu
                    position="bottom-end"
                    opened={openedMenu}
                    width={200}
                    zIndex={201}
                    onClose={() => setMenuOpened(false)}
                >
                    <Menu.Target>
                        <Avatar
                            alt="User initials"
                            radius="xl"
                            onClick={() => setMenuOpened(!openedMenu)}
                            className="pointerCursor"
                            mr="md"
                        >
                            {formatUserInitials(userContext?.user?.name)}
                        </Avatar>
                    </Menu.Target>
                    <Menu.Dropdown p="md">
                        <Menu.Item>
                            <Link
                                passHref
                                href={getMerchantLocationPath(
                                    router.query.merchant as string,
                                    merchantIdentifier
                                )}
                            >
                                <Text size="md">Shop</Text>
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link
                                passHref
                                href={`${getMerchantLocationPath(
                                    router.query.merchant as string,
                                    merchantIdentifier
                                )}/account`}
                            >
                                <Text size="md">Account</Text>
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link
                                passHref
                                href={`${getMerchantLocationPath(
                                    router.query.merchant as string,
                                    merchantIdentifier
                                )}/orders/active`}
                            >
                                <Text size="md">Orders</Text>
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            pb="sm"
                            onClick={() => {
                                trackEvent(AnalyticsEvent.ViewHelp);
                            }}
                        >
                            <Link
                                passHref
                                href={OZNR_SUPPORT_LINK}
                                target="_blank"
                            >
                                <Text size="md">Help Center</Text>
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            onClick={() => {
                                trackEvent(AnalyticsEvent.ViewFAQs);
                            }}
                        >
                            <Link passHref href={OZNR_FAQ_LINK} target="_blank">
                                <Text size="md">FAQs</Text>
                            </Link>
                        </Menu.Item>
                        <Divider style={{ margin: "0" }} />
                        <Menu.Item
                            pt="sm"
                            onClick={handleLogout}
                            rightSection={
                                <FontAwesomeIcon
                                    icon={faArrowRightFromBracket}
                                />
                            }
                        >
                            <Text size="md">Log Out</Text>
                        </Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            )}
        </>
    );
}
