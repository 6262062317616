import {
    Button,
    createStyles,
    Divider,
    Group,
    Image,
    Modal,
    Stack,
    Text,
    Title,
    UnstyledButton,
} from "@mantine/core";
import { CartContext } from "context/cartContext";
import { getMerchantLocationPath } from "helpers/url/urlHelpers";
import NextImage from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import oznrLogo from "public/oznr_white_logo_long.png";
import { useContext } from "react";
import { ItemType, Merchant } from "types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { showNotification } from "@mantine/notifications";
import { HIGHEST_Z_INDEX } from "constants/numbers";

interface AuthModalProps {
    logoUrl: string;
    opened: boolean;
    onClose: () => void;
    authRedirectPath?: string;
    merchant: Merchant;
    message: string;
    blockGuestCheckout?: boolean;
}

const useStyles = createStyles((theme) => ({
    groupContainer: {
        width: "80%",
    },
    divider: {
        width: "80%",
    },
    signUpButton: {
        color: theme.colors.oznrPrimary[6],
        fontWeight: 600,
    },
    footer: {
        background: theme.colors.oznrPrimary[6],
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        borderRadius: "0 0 6px 6px",
        height: "72px",
    },
    footerText: {
        fontSize: 18,
        fontWeight: 600,
        color: theme.other.monoColors.white,
    },
}));

export function AuthModal({
    logoUrl,
    opened,
    onClose,
    authRedirectPath,
    merchant,
    message,
    blockGuestCheckout = false,
}: AuthModalProps) {
    const { classes, theme } = useStyles();
    const router = useRouter();
    const path = authRedirectPath ?? router.asPath;
    const cartContext = useContext(CartContext);
    const merchantCart = cartContext?.cart[merchant.id];

    const hasMemebership = Object.values(merchantCart?.items ?? []).some(
        (item) => item.type === ItemType.MEMBERSHIP
    );

    function handleGuestButton() {
        router.push(
            `${getMerchantLocationPath(
                router.query.merchant as string,
                merchant.subdomain
            )}/guests/checkout`
        );
    }

    function removeMemberships() {
        Object.values(merchantCart?.items ?? [])
            .filter((item) => item.type == ItemType.MEMBERSHIP)
            .forEach((item) => {
                cartContext?.removeItemFromCart(
                    item.variation.id.toString(),
                    merchant.id.toString(),
                    cartContext?.cart[merchant.id],
                    item.itemId
                );
            });
        showNotification({
            title: "Cart Updated",
            message: "The membership has been removed from your cart.",
            color: "green",
            autoClose: true,
            radius: theme.spacing.sm,
            icon: <FontAwesomeIcon icon={faCircleExclamation} />,
        });
    }

    function guestCheckoutDisabled() {
        return (
            <>
                <Stack
                    className={classes.groupContainer}
                    align="center"
                    spacing="sm"
                >
                    <Title order={3}>Before we continue</Title>
                    <Text align="center">
                        You need to login or create an account with Oznr before
                        ordering
                    </Text>
                </Stack>
                <Stack className={classes.groupContainer} align="center">
                    <Link
                        href={{
                            pathname: "/login",
                            query: { from: path },
                        }}
                        passHref
                    >
                        <Button
                            color="oznrPrimary"
                            radius="xl"
                            size="lg"
                            styles={{
                                label: { color: theme.other.monoColors.white },
                            }}
                            fullWidth
                        >
                            Login
                        </Button>
                    </Link>
                    <Link
                        href={{
                            pathname: "/sign-up",
                            query: { from: path },
                        }}
                        passHref
                    >
                        <UnstyledButton className={classes.signUpButton}>
                            Create an account
                        </UnstyledButton>
                    </Link>
                </Stack>
            </>
        );
    }

    function authBlock() {
        return (
            <Stack className={classes.groupContainer} align="center">
                <Link
                    href={{
                        pathname: "/login",
                        query: { from: path },
                    }}
                    passHref
                >
                    <Button
                        color="oznrPrimary"
                        radius="xl"
                        size="lg"
                        fullWidth
                        styles={{
                            label: { color: theme.other.monoColors.white },
                        }}
                    >
                        Login
                    </Button>
                </Link>
                <Link
                    href={{
                        pathname: "/sign-up",
                        query: { from: path },
                    }}
                    passHref
                >
                    <Button
                        color="oznrPrimary"
                        radius="xl"
                        size="lg"
                        fullWidth
                        styles={{
                            label: { color: theme.other.monoColors.white },
                        }}
                    >
                        Create an account
                    </Button>
                </Link>
            </Stack>
        );
    }

    function authOptions() {
        if (
            merchant.businessFeatures?.enableGuestCheckout == false ||
            blockGuestCheckout
        ) {
            return guestCheckoutDisabled();
        } else {
            if (hasMemebership) {
                return (
                    <>
                        <Stack
                            className={classes.groupContainer}
                            align="center"
                            spacing="sm"
                        >
                            <Title order={6}>Before we continue</Title>
                            <Text align="center">
                                You need to login or create an account with Oznr
                                before purchasing a membership
                            </Text>
                        </Stack>
                        {authBlock()}
                        <Divider
                            label="&nbsp;OR&nbsp;"
                            color={theme.black}
                            labelProps={{ size: "md" }}
                            labelPosition="center"
                            className={classes.groupContainer}
                        />
                        <Stack
                            className={classes.groupContainer}
                            align="center"
                        >
                            <Button
                                color="oznrPrimary"
                                radius="xl"
                                size="lg"
                                variant="outline"
                                fullWidth
                                onClick={removeMemberships}
                            >
                                Remove Membership
                            </Button>
                        </Stack>
                    </>
                );
            } else {
                return (
                    <>
                        <Stack
                            className={classes.groupContainer}
                            align="center"
                        >
                            <Button
                                color="oznrPrimary"
                                radius="xl"
                                size="lg"
                                fullWidth
                                styles={{
                                    label: {
                                        color: theme.other.monoColors.white,
                                    },
                                }}
                                onClick={handleGuestButton}
                            >
                                Continue as a Guest
                            </Button>
                        </Stack>
                        <Divider
                            label="&nbsp;OR&nbsp;"
                            color={theme.black}
                            labelProps={{ size: "md" }}
                            labelPosition="center"
                            className={classes.groupContainer}
                        />
                        <Stack
                            className={classes.groupContainer}
                            align="center"
                            spacing="sm"
                        >
                            <Text align="center">
                                For a faster checkout and access to order
                                history.
                            </Text>
                        </Stack>
                        {authBlock()}
                    </>
                );
            }
        }
    }

    return (
        <Modal
            opened={opened}
            onClose={onClose}
            centered
            padding="lg"
            radius="md"
            trapFocus={false}
            zIndex={HIGHEST_Z_INDEX}
        >
            <Stack align="center" spacing={40} pb={96}>
                <Image
                    src={logoUrl}
                    fit="contain"
                    alt="Merchant logo image"
                    height={50}
                />
                {authOptions()}
            </Stack>
            <Group className={classes.footer} position="center">
                <Text className={classes.footerText}>Liquified by</Text>
                <NextImage
                    src={oznrLogo}
                    height={40}
                    width={119.75}
                    alt="Oznr logo"
                />
            </Group>
        </Modal>
    );
}
