import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { Anchor, createStyles } from "@mantine/core";

interface NavLinkStyleProps {
    active: boolean;
}

const useStyles = createStyles((theme, { active }: NavLinkStyleProps) => ({
    root: {
        color:
            active && theme.other.isLight
                ? theme.other.textColor
                : active
                ? theme.colors[theme.primaryColor][6]
                : theme.colors.gray[7],
        fontWeight: active ? 600 : 400,

        "&:hover": {
            color: theme.other.isLight
                ? "#333333"
                : theme.colors[theme.primaryColor][6],
            textDecoration: "none",
        },
    },
}));

interface NavLinkProps {
    href: string;
    children: React.ReactNode;
}

export function NavLink({ children, href }: NavLinkProps) {
    const router = useRouter();
    const active = router.asPath == href;
    const { classes } = useStyles({ active });
    return (
        <Link href={href} passHref>
            <Anchor className={classes.root}>{children}</Anchor>
        </Link>
    );
}
