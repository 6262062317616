import { ActionIcon, createStyles, Stack, Text } from "@mantine/core";
import { useUncontrolled } from "@mantine/hooks";
import { useState } from "react";

type QuantitySelectorSize = "sm" | "md" | "lg";

interface QuantitySelectorProps {
    onChange: (qty: number) => void;
    value: number;
    defaultValue?: number;
    min: number;
    max: number;
    size?: QuantitySelectorSize;
}

interface QuantitySelectorStyleProps {
    size: QuantitySelectorSize;
}

const sizes = {
    sm: {
        width: "100px",
    },
    md: {
        width: "125px",
    },
    lg: {
        width: "184px",
    },
};

const useStyles = createStyles(
    (theme, { size }: QuantitySelectorStyleProps) => ({
        buttonContainer: {
            ...sizes[size],
            height: "50px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
            border: "1px solid #333333",
            borderRadius: theme.spacing.xl,
            background: "white",
        },
        quantityButton: {
            border: "none",
            height: theme.spacing.xl,
            fontSize: "20px",
        },
        warningText: {
            fontSize: theme.fontSizes.xs,
            fontVariantCaps: "all-small-caps",
        },
    })
);

export function QuantitySelector({
    onChange,
    value,
    defaultValue,
    min,
    max,
    size = "sm",
}: QuantitySelectorProps) {
    const { classes, theme } = useStyles({ size });
    const [showWarning, setShowWarning] = useState(false);
    const [_value, handleChange] = useUncontrolled({
        value,
        defaultValue,
        finalValue: 1,
        onChange,
    });

    function handleIncrement() {
        if (_value) {
            if (_value >= max) {
                setShowWarning(true);
                return;
            }
            setShowWarning(false);
            handleChange(_value + 1);
        }
    }

    function handleDecrement() {
        if (_value) {
            setShowWarning(false);
            if (_value <= min) return;
            handleChange(_value - 1);
        }
    }

    return (
        <Stack spacing={0} align="center">
            <div className={classes.buttonContainer}>
                <ActionIcon
                    className={classes.quantityButton}
                    variant="default"
                    onClick={handleDecrement}
                    radius={theme.spacing.xl}
                >
                    -
                </ActionIcon>
                {_value}
                <ActionIcon
                    className={classes.quantityButton}
                    variant="default"
                    onClick={handleIncrement}
                    radius={theme.spacing.xl}
                >
                    +
                </ActionIcon>
            </div>
            {showWarning && (
                <Text
                    className={classes.warningText}
                    color="merchantPrimary"
                    weight={600}
                >
                    max of {max}
                </Text>
            )}
        </Stack>
    );
}
