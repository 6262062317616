import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    createStyles,
    Divider,
    Group,
    Image,
    Stack,
    Text,
    UnstyledButton,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { VariantChip } from "components/Item/Variant/VariantChip";
import { QuantitySelector } from "components/QuantitySelector/QuantitySelector";
import { CartContext } from "context/cartContext";
import { formatCentsToPrice } from "helpers/currency/currencyHelpers";
import {
    getFriendlyFulfillmentMethodName,
    getFulfillmentMethodIcon,
} from "helpers/item/itemHelpers";
import { useContext } from "react";
import { FulfillmentMethod, Merchant } from "types";
import { CartLineItem } from "types/Cart";

interface CartDrawerListIemProps {
    cartLineItem: CartLineItem;
    merchant: Merchant;
}

const useStyles = createStyles((theme) => ({
    imageContainer: {
        position: "relative",
        textAlign: "center",
        "&:hover": {
            boxShadow: "0 8px 10px rgba(110, 116, 134, 0.24)",
        },
        borderRadius: "10px",
    },
    listItemContainer: {
        marginBottom: theme.spacing.xl,
    },
    listItemTitleContainer: {
        [theme.fn.smallerThan("md")]: {
            maxWidth: "150px",
        },
    },
    removeItemButton: {
        fontSize: theme.fontSizes.xs,
        fontWeight: 300,
    },
    removeItemButtonContainer: {
        display: "flex",
        justifyContent: "flex-end",
    },
}));

export function CartDrawerListIem({
    cartLineItem,
    merchant,
}: CartDrawerListIemProps) {
    const { classes, theme, cx } = useStyles();
    const cartContext = useContext(CartContext);
    const merchantCart = cartContext?.cart[merchant.id];
    const smallScreen = useMediaQuery(
        `(max-width: ${theme.breakpoints.md})`,
        false
    );

    const handleRemoveItemFromCart = () => {
        cartContext?.removeItemFromCart(
            cartLineItem.variation.id.toString(),
            merchant.id.toString(),
            cartContext?.cart[merchant.id],
            cartLineItem.itemId
        );
    };

    return (
        <div className={classes.listItemContainer}>
            <Group noWrap spacing={0}>
                <Group align="flex-start">
                    <Image
                        radius={10}
                        src={cartLineItem.item.imageUrl}
                        alt={cartLineItem.item.title}
                        width={smallScreen ? 150 : 200}
                        height={smallScreen ? 100 : 133}
                        className={classes.imageContainer}
                    />
                    <Stack
                        align="self-start"
                        spacing="xs"
                        className={classes.listItemTitleContainer}
                    >
                        <Text size={smallScreen ? "md" : "lg"} weight={600}>
                            {cartLineItem.item.title}
                        </Text>
                        <Text
                            size="xs"
                            className="primaryTextColor"
                            weight={300}
                        >
                            {cartLineItem.item.subtitle}
                        </Text>
                        {cartLineItem.variation.variant.name && (
                            <VariantChip
                                name={cartLineItem.variation.variant.name}
                                size="sm"
                            />
                        )}
                    </Stack>
                </Group>
            </Group>
            <Group position="apart" align="center">
                <Stack spacing={0} my="md">
                    <Text weight={600} size={smallScreen ? "lg" : "xl"}>
                        {formatCentsToPrice({
                            cents:
                                cartLineItem.variation.price * cartLineItem.qty,
                            currencyCode: merchant.currencyCode,
                        })}
                    </Text>
                    <Group spacing="sm" position="center">
                        <Text className="hiddenOnMobile" size="sm" weight={600}>
                            Fulfillment Method:
                        </Text>
                        <FontAwesomeIcon
                            icon={getFulfillmentMethodIcon(
                                cartLineItem.type,
                                merchantCart?.fulfillmentType
                            )}
                            size="sm"
                            color={theme.colors.merchantPrimary[6]}
                        />
                        <Text
                            weight={600}
                            className="primaryTextColor"
                            size="xs"
                        >
                            {getFriendlyFulfillmentMethodName(
                                cartLineItem.type,
                                merchantCart?.fulfillmentType
                            )}
                        </Text>
                    </Group>
                </Stack>
                <QuantitySelector
                    value={cartLineItem.qty}
                    min={1}
                    max={cartLineItem.variation.maxQuantityAllowed}
                    onChange={(qty: number) =>
                        cartContext?.addToCart(
                            qty,
                            cartLineItem.variation,
                            merchant,
                            merchantCart
                                ? merchantCart.fulfillmentType
                                : FulfillmentMethod.PICKUP,
                            cartLineItem.type,
                            cartLineItem.pickUpStartDate,
                            cartLineItem.itemId,
                            cartLineItem.item
                        )
                    }
                />
            </Group>
            <div className={classes.removeItemButtonContainer}>
                <UnstyledButton
                    pb="md"
                    className={cx("underline", classes.removeItemButton)}
                    onClick={handleRemoveItemFromCart}
                >
                    Remove Item
                </UnstyledButton>
            </div>
            <Divider size="xs" />
        </div>
    );
}
