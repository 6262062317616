import { Badge, MantineSize, useMantineTheme } from "@mantine/core";

interface VariantChipProps {
    name: string;
    size?: MantineSize;
}

export function VariantChip({ name, size = "lg" }: VariantChipProps) {
    const theme = useMantineTheme();
    return (
        <Badge
            styles={{
                root: {
                    color: "white",
                    backgroundColor: theme.other.monoColors.gray_4,
                },
            }}
            size={size}
        >
            {name}
        </Badge>
    );
}
