import { faCartShoppingFast } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Button,
    createStyles,
    Drawer,
    Group,
    ScrollArea,
    Stack,
    Text,
    UnstyledButton,
} from "@mantine/core";
import { useMediaQuery, useViewportSize } from "@mantine/hooks";
import { AuthModal } from "components/Modal/AuthModal";
import { DRAWER_Z_INDEX } from "constants/numbers";
import { AppContext } from "context/appContext";
import { CartContext } from "context/cartContext";
import { calculateCartSubtotal } from "helpers/cart/cartHelpers";
import { formatCentsToPrice } from "helpers/currency/currencyHelpers";
import { getMerchantLocationPath } from "helpers/url/urlHelpers";
import useIsLoggedIn from "hooks/useIsLoggedIn";
import NextImage from "next/image";
import { useRouter } from "next/router";
import emptyCartIllustration from "public/empty_cart_illustration.svg";
import { useContext, useState } from "react";
import { Merchant } from "types";
import { CartLineItem } from "types/Cart";
import { CartDrawerListIem } from "./CartDrawerListItem/CartDrawerListItem";

interface CartDrawerProps {
    merchant: Merchant;
}

const useStyles = createStyles((theme, { width }: { width: number }) => ({
    subtotalContainer: {
        position: "fixed",
        bottom: 0,
        right: 0,

        width: "100%",
        [theme.fn.smallerThan("sm")]: {
            width: width - 35,
        },
    },
    checkoutButton: {
        fontWeight: 400,
        [theme.fn.smallerThan("sm")]: {
            width: "100%",
        },
        width: "50%",
    },
}));

export function CartDrawer({ merchant }: CartDrawerProps) {
    const cartContext = useContext(CartContext);
    const appContext = useContext(AppContext);
    const merchantCart = cartContext?.cart[merchant.id];
    const router = useRouter();
    const [authModalOpened, setAuthModalOpened] = useState(false);
    const loggedIn = useIsLoggedIn();
    const { height, width } = useViewportSize();
    const { classes, theme } = useStyles({ width });
    const smallScreen = useMediaQuery(
        `(max-width: ${theme.breakpoints.md})`,
        false
    );

    function getCheckoutSummary() {
        if (merchantCart?.items) {
            return Object.values(merchantCart?.items).map(
                (cartLineItem: CartLineItem) => (
                    <CartDrawerListIem
                        key={cartLineItem.variation.id}
                        cartLineItem={cartLineItem}
                        merchant={merchant}
                    />
                )
            );
        } else {
            return (
                <Stack align="center">
                    <NextImage
                        src={emptyCartIllustration}
                        height={200}
                        alt="Empty cart password illustration"
                    />
                    <Text color={theme.other.monoColors.gray_4}>
                        No items in cart!
                    </Text>
                </Stack>
            );
        }
    }

    function handleCheckoutButton() {
        appContext.setCartDrawerOpened(false);
        if (!loggedIn) {
            setAuthModalOpened(true);
        } else {
            router.push(
                `${getMerchantLocationPath(
                    router.query.merchant as string,
                    merchant.subdomain
                )}/checkout`
            );
        }
    }

    return (
        <>
            <Drawer
                opened={appContext.cartDrawerOpened}
                onClose={() => appContext.setCartDrawerOpened(false)}
                title={
                    <Text
                        className="primaryTextColor"
                        p={theme.spacing.md}
                        size="xl"
                        weight={600}
                    >
                        My Cart
                    </Text>
                }
                zIndex={DRAWER_Z_INDEX}
                position="right"
                size={smallScreen ? "90%" : "40%"}
                styles={{
                    close: {
                        background: theme.colors.merchantPrimary[6],
                        color: "white",
                        borderRadius: theme.spacing.xl,
                        "&:hover": {
                            background: theme.colors.merchantPrimary[7],
                            color: "white",
                        },
                        marginRight: 21,
                        height: 28,
                        width: 28,
                    },
                }}
            >
                <ScrollArea px="md" sx={{ height: height - 300 }}>
                    {getCheckoutSummary()}
                </ScrollArea>
                <div className={classes.subtotalContainer}>
                    <Stack
                        sx={{ backgroundColor: theme.other.monoColors.gray_1 }}
                        p="lg"
                    >
                        <Group position="apart" pb="sm">
                            <Text size="xl" weight={500}>
                                Subtotal
                            </Text>
                            <Text size="xl" weight={500}>
                                {merchantCart
                                    ? formatCentsToPrice({
                                          cents: calculateCartSubtotal(
                                              merchantCart.items
                                          ),
                                      })
                                    : formatCentsToPrice({ cents: 0 })}
                            </Text>
                        </Group>
                        <Button
                            variant="filled"
                            radius="xl"
                            color="merchantPrimary"
                            size="lg"
                            className={classes.checkoutButton}
                            onClick={handleCheckoutButton}
                            leftIcon={
                                <FontAwesomeIcon icon={faCartShoppingFast} />
                            }
                        >
                            <Text ml={6}>Checkout</Text>
                        </Button>
                        <UnstyledButton
                            className="underline"
                            onClick={() => {
                                appContext.setCartDrawerOpened(false);
                                router.push(
                                    getMerchantLocationPath(
                                        router.query.merchant as string,
                                        merchant.subdomain
                                    )
                                );
                            }}
                        >
                            <Text>&#60; Continue Shopping</Text>
                        </UnstyledButton>
                    </Stack>
                </div>
            </Drawer>
            <AuthModal
                message="You need to login or create an account with Oznr before ordering"
                logoUrl={merchant.darkLogoUrl}
                opened={authModalOpened}
                onClose={() => setAuthModalOpened(false)}
                authRedirectPath={`${getMerchantLocationPath(
                    router.query.merchant as string,
                    merchant.subdomain
                )}/checkout`}
                merchant={merchant}
            />
        </>
    );
}
