import {
    Button,
    Group,
    Modal,
    Stack,
    Title,
    Image,
    useMantineTheme,
} from "@mantine/core";
import { useRouter } from "next/router";
import { useContext } from "react";
import { AppContext } from "context/appContext";
import { useMediaQuery, useViewportSize } from "@mantine/hooks";
import { DRAWER_Z_INDEX } from "constants/numbers";
import { Merchant } from "types";
import { IS_OVER_21_LOCAL_STORAGE } from "constants/strings";

interface AgeGateProps {
    merchant: Merchant;
}

export function AgeGate({ merchant }: AgeGateProps) {
    const appContext = useContext(AppContext);
    const theme = useMantineTheme();
    const router = useRouter();
    const smallScreen = useMediaQuery(
        `(max-width: ${theme.breakpoints.md})`,
        false
    );

    const handleYes = () => {
        localStorage.setItem(
            `${IS_OVER_21_LOCAL_STORAGE}_${merchant.subdomain}`,
            "true"
        );
        appContext.setIsOver21(true);
    };

    return (
        <Modal
            opened={!appContext.isOver21}
            onClose={() => {}}
            zIndex={10005}
            size={smallScreen ? "100%" : "md"}
            radius="md"
            withCloseButton={false}
            centered={!smallScreen}
        >
            <Stack
                py="sm"
                align="center"
                justify="center"
                spacing="xl"
                sx={{ height: smallScreen ? "500px" : "auto" }}
            >
                <Image
                    src={merchant.darkLogoUrl}
                    fit="contain"
                    alt="Merchant logo image"
                    height={100}
                    width={150}
                />
                <Title order={2}>Are you over 21?</Title>
                <Group spacing="md">
                    <Button
                        color="primary"
                        size="xl"
                        radius="xl"
                        onClick={handleYes}
                    >
                        Yes
                    </Button>
                    <Button
                        variant="outline"
                        color="primary"
                        className="primaryTextColor"
                        size="xl"
                        radius="xl"
                        onClick={() =>
                            router.push("https://www.lego.com/en-us")
                        }
                    >
                        No
                    </Button>
                </Group>
            </Stack>
        </Modal>
    );
}
