import { faLocationDot, faPaperPlane } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Anchor,
    createStyles,
    Divider,
    Flex,
    Group,
    Image,
    Stack,
    Text,
    ThemeIcon,
} from "@mantine/core";
import NextImage from "next/image";
import { addressHelpers } from "helpers";
import React from "react";
import { Merchant } from "types";
import oznrLogo from "public/oznr_white_logo_long.png";
import { OZNR_MARKETING_LINK, OZNR_SUPPORT_LINK } from "constants/links";
import {
    AppType,
    DownloadAppButton,
} from "components/DownloadAppButton/DownloadAppButton";
import { useMediaQuery } from "@mantine/hooks";
import { trackEvent } from "analytics";
import { AnalyticsEvent } from "types/Analytics";

interface FooterProps {
    merchant: Merchant;
    bottomPadding?: string;
}

interface FooterStylesProps {
    bottomPadding: string;
}

const useStyles = createStyles(
    (theme, { bottomPadding }: FooterStylesProps) => ({
        root: {
            width: "100%",
            marginTop: theme.spacing.xl,
            color: theme.other.monoColors.gray_4,
            paddingBottom: bottomPadding,
        },
        merchantSection: {
            backgroundColor: "#F1F1F3",
            padding: "24px 0px",

            [theme.fn.smallerThan("md")]: {
                padding: "24px 60px",
            },
            justifyContent: "space-evenly",
        },
        merhantLogo: {
            "& img": {
                [theme.fn.smallerThan("md")]: {
                    maxHeight: 48,
                    width: "auto",
                },
            },
        },
        getInTouch: {
            fontSize: 36,
            fontWeight: 500,
            color: theme.other.monoColors.gray_4,
            [theme.fn.smallerThan("md")]: {
                fontSize: 16,
            },
        },
        divider: {
            height: 180,
            alignSelf: "auto",
        },
        address: {
            fontStyle: "normal",
            textAlign: "center",
        },
        oznrSection: {
            backgroundColor: theme.colors.oznrPrimary[6],
            padding: "16px 120px",

            [theme.fn.smallerThan("md")]: {
                padding: "24px 60px",
            },
        },
        footerText: {
            fontSize: 18,
            fontWeight: 300,
            color: theme.other.monoColors.white,
        },
        link: {
            textDecoration: "none",
        },
        icons: {
            color: theme.other.isLight ? theme.other.textColor : "#fff",
        },
    })
);

export function Footer({ merchant, bottomPadding = "0px" }: FooterProps) {
    const { classes, cx, theme } = useStyles({ bottomPadding });
    const smallScreen = useMediaQuery(
        `(max-width: ${theme.breakpoints.md})`,
        false
    );
    return (
        <footer className={classes.root}>
            <Flex
                className={classes.merchantSection}
                direction={smallScreen ? "column" : "row"}
                align={smallScreen ? "center" : "self-start"}
            >
                <a
                    className={classes.link}
                    href={merchant.websiteUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Stack pt="md" align="center" spacing="sm">
                        <Image
                            className={classes.merhantLogo}
                            src={merchant.darkLogoUrl}
                            fit="contain"
                            alt="Merchant logo image"
                            width={150}
                            height={100}
                        />
                        <Text className={classes.getInTouch}>Get in touch</Text>
                    </Stack>
                </a>
                <Divider
                    orientation="vertical"
                    className={cx("hiddenOnMobile", classes.divider)}
                />
                <Stack pt={smallScreen ? 0 : 56} align="center">
                    <ThemeIcon
                        className="hiddenOnMobile"
                        radius="lg"
                        size="lg"
                        color="merchantPrimary"
                    >
                        <FontAwesomeIcon
                            icon={faPaperPlane}
                            className={classes.icons}
                            size="1x"
                        />
                    </ThemeIcon>
                    <Anchor
                        weight={300}
                        className="underline"
                        href={`mailto:${merchant.contactEmail}`}
                        style={{ color: theme.other.monoColors.gray_4 }}
                    >
                        Contact Us
                    </Anchor>
                </Stack>
                <Stack pt={smallScreen ? 0 : 56} align="center" spacing={12}>
                    <ThemeIcon
                        className="hiddenOnMobile"
                        color="merchantPrimary"
                        radius="lg"
                        size="lg"
                    >
                        <FontAwesomeIcon
                            icon={faLocationDot}
                            className={classes.icons}
                            size="1x"
                        />
                    </ThemeIcon>
                    <address className={classes.address}>
                        <Text weight={300}>
                            {addressHelpers.line1(merchant.address)}
                        </Text>
                        <Text weight={300}>
                            {addressHelpers.line2(merchant.address)}
                        </Text>
                        {merchant.license && (
                            <Text weight={300}>
                                License #{merchant.license}
                            </Text>
                        )}
                    </address>
                </Stack>
            </Flex>
            <Group
                className={classes.oznrSection}
                position={smallScreen ? "center" : "apart"}
            >
                <a
                    className={classes.link}
                    href={OZNR_MARKETING_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Group position="center">
                        <Text className={classes.footerText} component="span">
                            Liquified by
                        </Text>
                        <NextImage
                            src={oznrLogo}
                            height={smallScreen ? 30 : 40}
                            width={smallScreen ? 89.8 : 119.75}
                            alt="Oznr logo"
                        />
                    </Group>
                </a>
                <Group
                    position="center"
                    spacing={smallScreen ? 8 : 24}
                    style={{ flexWrap: "wrap-reverse" }}
                >
                    <a
                        className={classes.link}
                        href={OZNR_SUPPORT_LINK}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                            trackEvent(AnalyticsEvent.ViewHelp);
                        }}
                    >
                        <Text component="span" className={classes.footerText}>
                            Questions?{" "}
                            <span
                                className={cx("underline", classes.footerText)}
                            >
                                Contact Oznr
                            </span>
                        </Text>
                    </a>
                    <Group position="center" spacing={0}>
                        <DownloadAppButton appType={AppType.ANDROID} />
                        <DownloadAppButton appType={AppType.IOS} />
                    </Group>
                </Group>
            </Group>
        </footer>
    );
}
