import NextImage from "next/image";
import { DefaultProps } from "types";
import { ANDROID_DOWNLOAD_LINK, IOS_DOWNLOAD_LINK } from "constants/links";
import androidDownloadBadge from "public/android_download_badge.png";
import iOSDownloadBadge from "public/ios_download_badge.svg";

interface DownloadAppButtonProps extends DefaultProps {
    appType: AppType;
    width?: number;
    height?: number;
}

export enum AppType {
    ANDROID = "ANDROID",
    IOS = "IOS",
}

export function DownloadAppButton({
    appType,
    width = 129.2,
    height = 50,
    className,
}: DownloadAppButtonProps) {
    return (
        <a
            href={
                appType === AppType.ANDROID
                    ? ANDROID_DOWNLOAD_LINK
                    : IOS_DOWNLOAD_LINK
            }
            target="_blank"
            rel="noopener noreferrer"
            className={className}
        >
            <NextImage
                src={
                    appType === AppType.ANDROID
                        ? androidDownloadBadge
                        : iOSDownloadBadge
                }
                width={appType === AppType.ANDROID ? width : width - 16}
                height={appType === AppType.ANDROID ? height : height - 16}
                alt={`Download our ${AppType.ANDROID ? "Android" : "iOS"} app`}
            />
        </a>
    );
}
