import { useEffect, useState } from "react";
import { isLoggedIn } from "helpers/auth/authHelpers";

export default function useIsLoggedIn(): boolean {
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        setLoggedIn(isLoggedIn());
    }, []);

    return loggedIn;
}
