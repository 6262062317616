import { getBusinessDetails } from "api/merchants";
import { useQuery } from "@tanstack/react-query";

const awaitBusinessDetails = async (merchantIdentifier: string) => {
    const data = await getBusinessDetails(merchantIdentifier);
    return data;
};

export default function useBusinessDetails(merchantIdentifier: string) {
    return useQuery(["getBusinessDetailsDetails", merchantIdentifier], () =>
        awaitBusinessDetails(merchantIdentifier)
    );
}
