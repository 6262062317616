import { createStyles, Global, MantineProvider } from "@mantine/core";
import React, { useEffect } from "react";
import { Merchant } from "types";
import { useMerchantTheme } from "hooks/useMerchantTheme";
import HeaderNavbar, { HEADER_HEIGHT } from "./HeaderNavbar/HeaderNavbar";
import { Footer } from "./Footer/Footer";
import { CartDrawer } from "components/CartDrawer/CartDrawer";
import { globalStyles } from "styles/Global.styles";
import { AgeGate } from "./AgeGate/AgeGate";
import { Notifications } from "@mantine/notifications";
import { useRouter } from "next/router";
import { storeUntappdUserId } from "helpers/user/userHelpers";

interface LayoutProps {
    children: React.ReactNode;
    merchant: Merchant;
    footerBottomPadding?: string;
}

const useStyles = createStyles((theme) => ({
    root: {
        height: "100vh",
        display: "flex",
        flexDirection: "column",

        "& footer": {
            flexShrink: 0,
        },
    },
    main: {
        scrollMarginTop: HEADER_HEIGHT,
        flex: "1 0 auto",
        marginBottom: 160,

        [theme.fn.smallerThan("md")]: {
            marginBottom: 80,
        },
    },
}));

export function Layout({
    children,
    merchant,
    footerBottomPadding,
}: LayoutProps) {
    const { classes } = useStyles();
    const merchantTheme = useMerchantTheme(merchant.primaryColor);
    const router = useRouter();

    // Store untappdUserId param in local storage
    useEffect(() => {
        if (router.query.utcid) {
            storeUntappdUserId(router.query.utcid as string);
        }
    }, [router.query.utcid]);

    return (
        <MantineProvider withNormalizeCSS theme={merchantTheme}>
            <Notifications position="top-center" />
            <Global styles={globalStyles} />
            <div className={classes.root}>
                <HeaderNavbar
                    logoUrl={merchant.darkLogoUrl}
                    merchant={merchant}
                />
                <main className={classes.main}>
                    {children}
                    <CartDrawer merchant={merchant} />
                    <AgeGate merchant={merchant} />
                </main>
                <Footer
                    merchant={merchant}
                    bottomPadding={footerBottomPadding}
                />
            </div>
        </MantineProvider>
    );
}
