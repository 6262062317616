import React, { useContext, useState } from "react";
import {
    Avatar,
    Text,
    createStyles,
    Group,
    Image,
    UnstyledButton,
    Popover,
    Stack,
} from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/pro-light-svg-icons";
import { AccountNav } from "../AccountNav/AccountNav";
import { AnonymousNav } from "../AnonymousNav/AnonymousNav";
import { CartContext } from "context/cartContext";
import { AppContext } from "context/appContext";
import { useRouter } from "next/router";
import useIsLoggedIn from "hooks/useIsLoggedIn";
import { size } from "lodash-es";
import Link from "next/link";
import useBusinessDetails from "hooks/useBusinessDetails";
import { Merchant } from "types";
import { faCaretDown, faLocationDot } from "@fortawesome/pro-solid-svg-icons";
import { trackEvent } from "analytics";
import { AnalyticsEvent } from "types/Analytics";
import { calculateCartSubtotal } from "helpers/cart/cartHelpers";
import {
    ABOVE_HEADER_Z_INDEX,
    DESKTOP_HEADER,
    MOBILE_HEADER_Z_INDEX,
} from "constants/numbers";
import { useMediaQuery } from "@mantine/hooks";
import { getMerchantLocationPath } from "helpers/url/urlHelpers";
import { NavLink } from "./NavLink";

interface HeaderNavbarProps {
    logoUrl: string;
    merchant: Merchant;
}

export const HEADER_HEIGHT = 80;

const useStyles = createStyles((theme) => ({
    navbar: {
        top: 0,
        left: 0,
        right: 0,
        zIndex: DESKTOP_HEADER,
        height: HEADER_HEIGHT,
        position: "fixed",
        backgroundColor:
            theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
        display: "flex",
        alignItems: "center",
        padding: "0 120px",
        justifyContent: "space-between",
        borderBottom: "1px solid #BEC2CE",
        [theme.fn.smallerThan("lg")]: {
            zIndex: MOBILE_HEADER_Z_INDEX,
            padding: "0 24px",
        },
    },
    cartNotification: {
        position: "relative",
        display: "inline-block",
        fontSize: theme.fontSizes.xl,
    },
    cartNotificationNumber: {
        position: "absolute",
        top: "-16px",
        right: "-16px",
        "& div": {
            fontSize: "16px",
        },
    },
    invertedMerchantColor: {
        backgroundColor: theme.colors.merchantPrimary[6],
        color: theme.colors.merchantPrimary[0],
        fontWeight: 500,
    },
}));

function HeaderNavbar({ logoUrl, merchant }: HeaderNavbarProps) {
    const { classes, theme } = useStyles();
    const loggedIn = useIsLoggedIn();
    const cartContext = useContext(CartContext);
    const appContext = useContext(AppContext);
    const router = useRouter();
    const [modalOpened, setModalOpen] = useState("");
    const { data: businessDetails } = useBusinessDetails(
        router.query.merchant as string
    );
    const smallScreen = useMediaQuery(
        `(max-width: ${theme.breakpoints.md})`,
        true
    );
    const merchantCart = cartContext?.cart[merchant.id];

    function getUserAvatar() {
        if (loggedIn) {
            return <AccountNav merchantIdentifier={merchant.subdomain} />;
        } else {
            return <AnonymousNav merchantIdentifier={merchant.subdomain} />;
        }
    }

    function handleCartButtonClick() {
        if (!router.pathname.split("/").includes("checkout")) {
            trackEvent(AnalyticsEvent.ViewCart, {
                currency: merchant.currencyCode,
                value: cartContext?.cart[merchant.id]?.items
                    ? (
                          calculateCartSubtotal(
                              cartContext.cart[merchant.id].items
                          ) / 100
                      ).toFixed(2)
                    : 0,
                merchant_id: merchant.id,
                merchant_name: merchant.name,
                ...(merchantCart && {
                    fulfillment_type: merchantCart.fulfillmentType,
                }),
                item_count: merchantCart?.items ? size(merchantCart.items) : 0,
            });
            appContext.setCartDrawerOpened(true);
        }
    }

    const closeModal = () => {
        setModalOpen("");
    };

    function getLocationDropDown() {
        if (!businessDetails) {
            return null;
        }
        if (businessDetails?.data.locations.length === 1) {
            return (
                <Group spacing="sm">
                    <FontAwesomeIcon
                        className="hiddenOnMobile"
                        icon={faLocationDot}
                        color={theme.colors[theme.primaryColor][6]}
                        size="1x"
                    />
                    <Text className="hiddenOnMobile">
                        {merchant.displayName
                            ? merchant.displayName
                            : merchant.name}
                    </Text>
                </Group>
            );
        } else {
            return (
                <Popover
                    opened={modalOpened === "locationDropDown"}
                    onClose={closeModal}
                    position="bottom"
                    trapFocus={false}
                    radius={theme.spacing.sm}
                    zIndex={ABOVE_HEADER_Z_INDEX}
                >
                    <Popover.Target>
                        <Group
                            spacing="sm"
                            className="pointerCursor"
                            onClick={() => {
                                modalOpened === "locationDropDown"
                                    ? closeModal()
                                    : setModalOpen("locationDropDown");
                            }}
                        >
                            <FontAwesomeIcon
                                style={{ fontSize: theme.fontSizes.xl }}
                                icon={faLocationDot}
                                className="primaryTextColor"
                                size="1x"
                            />
                            <Text className="hiddenOnMobile">
                                {merchant.displayName
                                    ? merchant.displayName
                                    : merchant.name}
                            </Text>
                            <FontAwesomeIcon
                                style={{ fontSize: theme.fontSizes.xl }}
                                icon={faCaretDown}
                                className="primaryTextColor"
                                size="1x"
                            />
                        </Group>
                    </Popover.Target>
                    <Popover.Dropdown>
                        {businessDetails?.data.locations.map((location) => {
                            return (
                                <Link
                                    href={`${getMerchantLocationPath(
                                        router.query.merchant as string,
                                        location.subdomain
                                    )}`}
                                    key={location.id}
                                    passHref
                                >
                                    <Stack
                                        sx={{ minWidth: "250px" }}
                                        py="sm"
                                        className="pointerCursor"
                                        spacing={0}
                                        onClick={closeModal}
                                    >
                                        <Text weight={600} size="md">
                                            {location.displayName
                                                ? location.displayName
                                                : location.name}
                                        </Text>
                                        <Text
                                            size="sm"
                                            weight={300}
                                            transform="uppercase"
                                        >
                                            {location.address.city},{" "}
                                            {location.address.state}
                                        </Text>
                                    </Stack>
                                </Link>
                            );
                        })}
                    </Popover.Dropdown>
                </Popover>
            );
        }
    }

    return (
        <div className={classes.navbar}>
            <Link
                href={`${getMerchantLocationPath(
                    router.query.merchant as string,
                    merchant.subdomain
                )}`}
                passHref
            >
                <UnstyledButton<"a"> component="a" className="pointerCursor">
                    <Image
                        src={logoUrl}
                        fit="contain"
                        alt="Merchant logo image"
                        height={56}
                        width={smallScreen ? 130 : "auto"}
                    />
                </UnstyledButton>
            </Link>
            <Group spacing="lg" align="center">
                <Group className="hiddenOnMobile">
                    <NavLink
                        href={`${getMerchantLocationPath(
                            router.query.merchant as string,
                            router.query.merchantLocation as string
                        )}`}
                    >
                        <Text pr="sm">Shop</Text>
                    </NavLink>
                    <NavLink
                        href={`${getMerchantLocationPath(
                            router.query.merchant as string,
                            router.query.merchantLocation as string
                        )}/events`}
                    >
                        <Text pr="sm">Events</Text>
                    </NavLink>
                    <NavLink
                        href={`${getMerchantLocationPath(
                            router.query.merchant as string,
                            router.query.merchantLocation as string
                        )}/memberships`}
                    >
                        <Text pr="sm">Memberships</Text>
                    </NavLink>
                </Group>
                {getLocationDropDown()}
                <UnstyledButton
                    className={classes.cartNotification}
                    onClick={handleCartButtonClick}
                >
                    <FontAwesomeIcon
                        icon={faShoppingCart}
                        className="primaryTextColor"
                        size="1x"
                    />
                    {cartContext?.cart[merchant.id] && (
                        <Avatar
                            className={classes.cartNotificationNumber}
                            radius="xl"
                            size="sm"
                        >
                            {size(cartContext?.cart[merchant.id].items)}
                        </Avatar>
                    )}
                </UnstyledButton>
                {getUserAvatar()}
            </Group>
        </div>
    );
}

export default HeaderNavbar;
