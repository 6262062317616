export const OZNR_SUPPORT_LINK =
    "https://oznr.zendesk.com/hc/en-us/requests/new";
export const OZNR_FAQ_LINK =
    "https://oznr.zendesk.com/hc/en-us/categories/360004295651-Consumer-FAQs";
export const ANDROID_DOWNLOAD_LINK =
    "https://play.google.com/store/apps/details?id=com.craftcellr.craftcellr";
export const IOS_DOWNLOAD_LINK =
    "https://apps.apple.com/us/app/oznr/id1478594846";
export const OZNR_MARKETING_LINK = "https://oznr.com";
export const OZNR_SHIPPING_TERMS_URL = "https://www.oznr.com/shipping";
export const OZNR_RD_TERMS_URL = "https://oznr.com/random-draw-tc";
export const OZNR_CANCEL_SUBSCRIPTION_URL =
    "https://oznr.zendesk.com/hc/en-us/articles/25665399063188-How-to-Cancel-a-Recurring-Membership";
