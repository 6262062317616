import { faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Anchor,
    Burger,
    Button,
    createStyles,
    Divider,
    Drawer,
    Group,
    Popover,
    Stack,
    Text,
    UnstyledButton,
} from "@mantine/core";
import oznrLogo from "public/oznr_dark_logo_long.png";
import Link from "next/link";
import NextImage from "next/image";
import { useState } from "react";
import { useRouter } from "next/router";
import { useMediaQuery } from "@mantine/hooks";
import { OZNR_FAQ_LINK, OZNR_SUPPORT_LINK } from "constants/links";
import { trackEvent } from "analytics";
import { AnalyticsEvent } from "types/Analytics";
import { BELOW_HEADER_Z_INDEX } from "constants/numbers";
import { getMerchantLocationPath } from "helpers/url/urlHelpers";

const useStyles = createStyles((theme) => ({
    loggedOut: {
        backgroundColor: theme.colors[theme.primaryColor][0],
        padding: theme.spacing.sm,
        borderRadius: theme.spacing.lg,
    },
    logInButton: {
        width: "200px",
    },
    loginLink: {
        fontWeight: 600,
        color: theme.colors.oznrPrimary[6],
    },
}));

interface AnonymousNavProps {
    merchantIdentifier: string;
}

export function AnonymousNav({ merchantIdentifier }: AnonymousNavProps) {
    const { classes, theme, cx } = useStyles();
    const [openedDrawer, setDrawerOpened] = useState(false);
    const [opened, setOpened] = useState(false);
    const router = useRouter();
    const smallScreen = useMediaQuery(
        `(max-width: ${theme.breakpoints.md})`,
        true
    );

    return (
        <>
            <Drawer
                position="top"
                size={360}
                opened={openedDrawer}
                zIndex={BELOW_HEADER_Z_INDEX}
                onClose={() => setDrawerOpened(false)}
                withCloseButton={false}
                trapFocus={false}
                lockScroll={true}
            >
                <Stack mx="sm" mt={40} pt={42} spacing="md">
                    <UnstyledButton
                        component={Link}
                        href={`${getMerchantLocationPath(
                            router.query.merchant as string,
                            merchantIdentifier
                        )}`}
                    >
                        <Text>Shop</Text>
                    </UnstyledButton>
                    <UnstyledButton
                        component={Link}
                        href={`${getMerchantLocationPath(
                            router.query.merchant as string,
                            merchantIdentifier
                        )}/events`}
                    >
                        <Text>Events</Text>
                    </UnstyledButton>
                    <UnstyledButton
                        component={Link}
                        href={`${getMerchantLocationPath(
                            router.query.merchant as string,
                            merchantIdentifier
                        )}/memberships`}
                    >
                        <Text>Memberships</Text>
                    </UnstyledButton>
                    <UnstyledButton
                        component={Link}
                        href={OZNR_SUPPORT_LINK}
                        target="_blank"
                        onClick={() => {
                            trackEvent(AnalyticsEvent.ViewHelp);
                        }}
                    >
                        <Text>Help Center</Text>
                    </UnstyledButton>
                    <UnstyledButton
                        component={Link}
                        href={OZNR_FAQ_LINK}
                        target="_blank"
                        onClick={() => {
                            trackEvent(AnalyticsEvent.ViewFAQs);
                        }}
                    >
                        <Text>FAQs</Text>
                    </UnstyledButton>
                    <Divider style={{ margin: "0" }} />
                    <Link
                        href={{
                            pathname: "/login",
                            query: { from: router.asPath },
                        }}
                        passHref
                    >
                        <Group position="apart">
                            <Text className={classes.loginLink}>
                                Sign in with Oznr
                            </Text>
                            <NextImage
                                src={oznrLogo}
                                height={24}
                                width={71.7}
                                alt="Oznr logo"
                            />
                        </Group>
                    </Link>
                </Stack>
            </Drawer>
            {smallScreen ? (
                <Burger
                    color={
                        theme.other.isLight
                            ? "black"
                            : theme.colors.merchantPrimary[1]
                    }
                    opened={openedDrawer}
                    onClick={() => setDrawerOpened(!openedDrawer)}
                />
            ) : (
                <Popover
                    opened={opened}
                    onClose={() => setOpened(false)}
                    trapFocus={false}
                    radius={theme.spacing.sm}
                    position="bottom-end"
                >
                    <Popover.Target>
                        <Group
                            className={cx(classes.loggedOut, "pointerCursor")}
                            onClick={() => setOpened((o) => !o)}
                        >
                            <FontAwesomeIcon
                                style={{ fontSize: theme.fontSizes.xl }}
                                icon={faUserCircle}
                                color={theme.colors[theme.primaryColor][6]}
                                size="1x"
                            />
                            <Text color={theme.colors[theme.primaryColor][6]}>
                                Login
                            </Text>
                        </Group>
                    </Popover.Target>
                    <Popover.Dropdown>
                        <Stack
                            align="center"
                            px={theme.spacing.xl}
                            py={theme.spacing.xs}
                        >
                            <NextImage
                                src={oznrLogo}
                                height={40}
                                width={119.75}
                                alt="Oznr logo"
                            />
                            <Text size="md" weight={300}>
                                Login to your Oznr account
                            </Text>
                            <Link
                                href={{
                                    pathname: "/login",
                                    query: { from: router.asPath },
                                }}
                                passHref
                            >
                                <Button
                                    className={classes.logInButton}
                                    radius="xl"
                                    size="lg"
                                    color="oznrPrimary"
                                    type="submit"
                                >
                                    Login
                                </Button>
                            </Link>
                            <Text weight={500} size="sm" color="black">
                                New to Oznr?{" "}
                                <Link
                                    href={{
                                        pathname: "/sign-up",
                                        query: { from: router.asPath },
                                    }}
                                    passHref
                                >
                                    <Anchor
                                        size="sm"
                                        weight={500}
                                        color="oznrPrimary"
                                    >
                                        Sign up
                                    </Anchor>
                                </Link>
                            </Text>
                        </Stack>
                    </Popover.Dropdown>
                </Popover>
            )}
        </>
    );
}
